import { IResponse } from "../types";
import { onQueryStarted } from "../api.config";
import store from "src/store";
import { IPaginationPayload } from "../types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const landingPagesApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getHomepageConfig: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: ENDPOINTS.ADMIN_LANDING_PAGE.GET_HOMEPAGE_CONFIG,
        params: body,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    updateHomepageConfig: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_LANDING_PAGE.UPDATE_HOMEPAGE_CONFIG}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    getLandingPage: build.mutation<IResponse, IPaginationPayload>({
      query: (body: any) => ({
        url: `${ENDPOINTS.ADMIN_LANDING_PAGE.GET}/${body.id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    getLandingPages: build.mutation<IResponse, IPaginationPayload>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_LANDING_PAGE.LIST}`,
        params: body,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    createLandingPage: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_LANDING_PAGE.CREATE}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    updateLandingPage: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_LANDING_PAGE.UPDATE}/${body.id}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    deleteLandingPage: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_LANDING_PAGE.DELETE}/${body.id}`,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

  }),
});
export const {
  useGetHomepageConfigMutation,
  useUpdateHomepageConfigMutation,
  useGetLandingPagesMutation,
  useCreateLandingPageMutation,
  useUpdateLandingPageMutation,
  useGetLandingPageMutation,
  useDeleteLandingPageMutation,
} = landingPagesApi;
