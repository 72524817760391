import React from "react";
import type { ClassValue } from "clsx";
import { cn } from "../../../../utils";

export type ButtonProps = {
  variant?: "primary" | "secondary" | "default" | "black" | "black-outlined"
  size?: 'xs' | 'sm' | 'md' | 'lg'
  children: React.ReactNode | string
  className?: string,
  href?: string,
  target?: string,
  as?: React.ElementType,
  passHref?: boolean,
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({ variant = 'primary', size = 'md', children, className, ...props }: ButtonProps) => {

  const variantClasses: { [key: string]: ClassValue } = {
    default: 'bg-transparent text-black font-bold uppercase',
    primary: "bg-primary-color border-[2px] border-transparent text-gray-100",
    secondary: "bg-gray-100 text-primary-color border-[2px] border-primary-color",
    black: "bg-black text-white border-[2px] border-transparent",
    'black-outlined': "bg-transparent text-black border-[2px] border-black",
  }

  const animationClasses = {
    default: 'transition-colors hover:text-primary-color',
    primary: "transition-colors hover:border-primary-color hover:bg-transparent hover:text-primary-color",
    secondary: "transition-colors hover:bg-primary-color hover:text-white",
    black: "transition-colors hover:bg-gray-800 hover:text-white",
    'black-outlined': "transition-colors hover:bg-gray-800 hover:text-white",
  }

  const sizeClasses = {
    xs: "px-2 py-1 text-xs",
    sm: "px-3 py-1 text-sm",
    md: "px-20 py-0 text-md",
    lg: "px-[25px] py-10 text-lg"
  }
  const Component = props.as || 'button'
  return (
    <Component
      className={cn(
        'rounded-md uppercase font-bold', // defaults (if any)
        'disabled:opacity-70 disabled:cursor-not-allowed ',
        variantClasses[variant],
        animationClasses[variant],
        sizeClasses[size],
        className
      )}
      {...props}
    >
      {children}
    </Component>
  )
}