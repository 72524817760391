import React from "react";
import { FormikProps } from "formik/dist/types";
import ImageUpload from "../../atoms/image-upload";
import CustomButton from "../../atoms/button";
import { LandingPageItem } from "./types";

type HeroComponentProps = {
  backgroundImage?: string
  overlayImage?: string
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const HeroComponent = ({ backgroundImage, overlayImage, formik, index }: HeroComponentProps) => {
  const [editMode, setEditMode] = React.useState(false);

  if(!editMode) {
    return (
      <div className="p-24 min-h-[300px] relative group flex items-center justify-center"
           style={{ background: `url(${backgroundImage}) no-repeat center center`, backgroundSize: "cover" }}>
        {!backgroundImage ? (
          <div>
            <p className="text-white-para text-center">No background image selected</p>
          </div>
        ) : null}


        {overlayImage ? (
          <div
            className="container px-20 md:px-8 lg:px-16 pt-[200px] flex justify-center items-center md:justify-end sm:px-0">
            <div className="hero-logo mb-[180px] p-20 md:p-0">
              <img
                src={overlayImage}
                alt="The 1% business platform"
                loading="eager"
                width={400}
                height={400}
              />
            </div>
          </div>
        ) : null}



        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="p-24">
      <div className=" grid grid-cols-2">
        <div>
          <ImageUpload
            label="Background Image"
            name={`elements.${index}.params.backgroundImage`}
            value={backgroundImage}
            className="bg-gray-400"
            onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.backgroundImage`, img)}
            image={backgroundImage}
            showError={Boolean(formik?.touched.elements?.[index])}
            errorMessage={formik?.errors.elements?.[index].toString()}
            setDisabled={false}
            isOpenModal={true}
            useCropper={false}
          />
        </div>
        <div>
          <ImageUpload
            label="Overlay Image"
            name={`elements.${index}.params.overlayImage`}
            value={overlayImage}
            className="bg-gray-400"
            onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.overlayImage`, img)}
            image={overlayImage}
            showError={Boolean(formik?.touched.elements?.[index])}
            errorMessage={formik?.errors.elements?.[index].toString()}
            setDisabled={false}
            isOpenModal={true}
            useCropper={false}
          />
        </div>
      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}
export default HeroComponent