import React from "react";
import { TOASTR_TYPES } from "../types/index";
import { CONSTANTS } from "src/constants";
import moment from "moment";
import { convert } from "html-to-text";
import { clsx, ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const hasEmptyValues = (obj: { [key: string]: string }): boolean => {
  if (Object.values(obj).includes("")) {
    return true;
  }
  return false;
};

export const hasUppercaseAndLowercase = (input: string) => {
  const hasUppercase = /[A-Z]/.test(input);
  const hasLowercase = /[a-z]/.test(input);
  return hasUppercase && hasLowercase;
};

export const hasNumbers = (input: string) => {
  const hasNumber = /[0-9]/.test(input);
  return hasNumber;
};

export const persistToken = (token: string) => {
  localStorage.setItem(CONSTANTS.TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(CONSTANTS.TOKEN) || "";
};

export const clearToken = () => {
  localStorage.removeItem(CONSTANTS.TOKEN);
  sessionStorage.removeItem(CONSTANTS.TOKEN);
};

export const showToast = (
  toast: any,
  type: keyof typeof TOASTR_TYPES,
  message: string,
  title?: string,
) => {
  let toastId = toast(
    <div className="flex items-start">
      <div className="mr-3 flex-shrink-0"></div>
      <div>
        <p className="mb-2 text-16 font-semibold leading-24 tracking-0.016">
          {title
            ? title
            : type === TOASTR_TYPES.SUCCESS
              ? "Success!"
              : "Failed!"}
        </p>
        <p className="text-14 font-normal leading-20 tracking-0.014">
          {message}
        </p>
      </div>
    </div>,
    {
      style: {
        backgroundColor:
          type === TOASTR_TYPES.SUCCESS
            ? "#039855"
            : type === TOASTR_TYPES.WARNING
              ? "#F79009"
              : "#D92037",
        border: type === TOASTR_TYPES.SUCCESS ? "none" : "none",
        borderRadius: "4px",
        color: "white",
        padding: "14px 16px",
      },
    },
  );

  return toastId;
};

export const formatDate = (date: string) => {
  return moment(date).format("D MMM YYYY");
};
export const formatWatchDate = (date: string) => {
  const formattedDate = moment(date).format("MMMM Do, YYYY [at] h:mm:ss A");
  return formattedDate;
};

export const getCurrentTimestamp = () => {
  return Date.now() / 1000;
};

export const convertTimestampToSeconds = (timestamp: number | string) => {
  return Math.floor(Number(timestamp) / 1000);
};
export const getOtpRequestTimestamp = () => {
  return localStorage.getItem("otpRequestTimestamp");
};

export const truncateString = (
  str: string | undefined,
  lengthToTruncate: number = 20,
) => {
  if (str) {
    if (str?.length > lengthToTruncate) {
      return `${str.substring(0, lengthToTruncate)}...`;
    }
    return str;
  } else {
    return "";
  }
};

export const makeImageUrl = (img: string | undefined) => {
  return img ? (process.env.REACT_APP_IMG_URL || "") + img : "";
};

export const checkSameNameExists = (arr: any) => {
  const names = new Set();

  for (let i = 0; i < arr.length; i++) {
    const name = arr[i].name;

    if (names.has(name)) {
      return true;
    }

    names.add(name);
  }

  return false;
};

export const extractDomailFromUrl = () => {
  const url = window.location.origin;

  // Remove the protocol and any trailing slashes (if any)
  const trimmedURL = url.replace(/^(https?:\/\/)?/, "").replace(/\/$/, "");

  // Split the remaining URL by slashes to extract the domain
  const parts = trimmedURL.split("/");
  const domain = parts[0];

  return domain;
};

export function convertToMinutesAndSeconds(seconds: number): string {
  if (isNaN(seconds)) {
    return `"00", "00"`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatMillisecondsToMinutesSeconds(
  milliseconds: number,
): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

  return `${formattedMinutes}:${formattedSeconds}`;
}

export const generateRandomPassword = (passwordLength = 10) => {
  const numbers = "0123456789";
  const specialChars = "@";
  const smallAlphabets = "abcdefghijklmnopqrstuvwxyz";
  const upperAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const getRandomChar = (charset: any) => {
    const randomIndex = Math.floor(Math.random() * charset.length);
    return charset[randomIndex];
  };

  // Ensure at least one character from each category
  const password = [
    getRandomChar(numbers),
    getRandomChar(specialChars),
    getRandomChar(smallAlphabets),
    getRandomChar(upperAlphabets),
  ];

  // Fill the rest of the password with random characters, ensuring only one special character
  while (password.length < passwordLength) {
    const randomCategory = Math.floor(Math.random() * 3); // 0: numbers, 1: specialChars, 2: alphabets
    switch (randomCategory) {
      case 0:
        password.push(getRandomChar(numbers));
        break;
      case 1:
        if (!password.some((char) => specialChars.includes(char))) {
          password.push(getRandomChar(specialChars));
        }
        break;
      case 2:
        password.push(getRandomChar(smallAlphabets));
        break;
      case 3:
        password.push(getRandomChar(upperAlphabets));
        break;
    }
  }

  // Shuffle the password to randomize the order
  for (let i = password.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [password[i], password[j]] = [password[j], password[i]];
  }

  return password.join("");
};

export const checkObjectKeys = (obj: any, objToValidate: any) => {
  const objKeys = Object.keys(obj);
  const objToValidateKeys = Object.keys(objToValidate);
  return objToValidateKeys.some((valKey: any) => objKeys.includes(valKey));
};

export const scrollToBioError = () => {
  setTimeout(() => {
    const errorField =
      document.getElementsByClassName("bio-error") &&
      document.getElementsByClassName("bio-error")[0];

    if (errorField) {
      errorField.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, 100);
};

export const capitalizeFirstLetter = (
  inputString: string | undefined,
): string | undefined => {
  if (inputString) {
    return inputString?.charAt(0)?.toUpperCase() + inputString?.slice(1);
  }
  return inputString;
};

export const filterEmptyTags = (val: string[]) => {
  return val.filter((value: string) => value.trim() !== "");
};

export const extractTextFromHTML = (html: string, wordLimit?: number) => {
  const text = convert(html);
  return text;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}