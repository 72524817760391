"use client";

import { Button } from "./button.component";
import { cn } from "../../../../utils";

export type ArticleCardProps = {
  title: string,
  topic?: string,
  description: string,
  author: string,
  authorRole: string,
  image?: string,
}
export const ArticleCard = ({ title, topic, description, image, author, authorRole }: ArticleCardProps) => {

  return (
    <div
      className={cn(
        'bg-black text-white p-[5px] cursor-pointer border-[5px] border-transparent hover:border-primary-color transition[border-color] duration-300',
      )}
    >
      <div className="w-full min-h-[120px] relative">
        {image ? (
          <img
            src={image}
            alt={title}
            width={120}
            height={120}
            className="block object-cover h-[260px] w-full object-center"
          />
        ) : null}
        {topic ? (
          <Button
            size="xs"
            className="absolute top-[15px] left-[15px] z-10"
          >{topic}</Button>
        ) : null}
      </div>
      <div className="flex items-center py-5 flex-col md:flex-row text-[20px]">
        <span className="text-primary-color font-bold">
            {author}
          </span>

        <span className="font-thin ml-10">{authorRole}</span>
      </div>
      <div className="max-w-full overflow-ellipsis break-normal text-[20px] font-bold leading-[26px] my-[12px]">
        {title}
      </div>
      <div className="text-[12px] mb-10 whitespace-normal overflow-hidden overflow-ellipsis h-auto">
        {description}
      </div>
    </div>
  )
}