import React from "react";
import { Button } from "./components/button.component";
import { MemberCard } from "./components/member-card.component";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";
import { FormikProps } from "formik/dist/types";
import { ArticleCard } from "./components/article-card.component";
import { LandingPageItem } from "./types";

type ArticlesComponentProps = {
  title?: string;
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
const ArticlesComponent = ({ title, formik, index }: ArticlesComponentProps) => {
  const [editMode, setEditMode] = React.useState(false);

  if(!editMode) {
    return (
      <div className="p-24 min-h-[300px] relative group">

        <div className="flex flex-nowrap flex-row mb-20">
          <div
            className="grow shrink-0 font-bold text-6xl leading-tight max-w-full overflow-ellipsis break-all">
            {title}
          </div>
          <div
            className="flex items-center md:items-end mt-6 md:mt-0 w-full overflow-hidden pl-0 md:pl-20 md:max-w-[50%] gap-4">
            <Button className="whitespace-nowrap">All</Button>
            <Button className="whitespace-nowrap">Automotive</Button>
            <Button className="whitespace-nowrap">Business</Button>
            <Button className="whitespace-nowrap">Energy</Button>
            <Button className="whitespace-nowrap">Etc..</Button>
          </div>
        </div>
        <div
          className={`grid gap-4 grid-cols-3`}>
          {Array(3).fill(0).map((_, i) => (
            <ArticleCard
              key={i}
              title={`Article ${i+1}`}
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut libero malesuada feugiat. Nulla porttitor accumsan tincidunt."
              topic={`${['Automotive', 'Business', 'Energy'][i] || ''}`}
              author="John Doe"
              authorRole="CEO"
              image="https://placehold.co/400?text=Article+Image"
            />
          ))}
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="p-24">
      <div className="grid grid-cols-1">
        <div>
          <TextInput
            name={`elements.${index}.params.title`}
            type="text"
            label="Section title"
            value={formik?.values.elements[index]?.params?.title}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />
        </div>

      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}
export default ArticlesComponent