import React from "react";
import { FormikProps } from "formik/dist/types";
import CustomButton from "../../atoms/button";
import TextInput from "../../atoms/text-input";
import Textarea from "../../atoms/textarea";
import { LandingPageItem } from "./types";

export type StepsProps = {
  title?: string
  items?: {
    title: string
    description: string
  }[]
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const Steps = ({ title, items, formik, index }: StepsProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const triggerAddItem = () => {
    const existingItems = formik.values.elements?.[index]?.params?.items || []
    formik.setFieldValue(`elements.${index}.params.items`, [
      ...existingItems,
      { title: '', description: '' }
    ])
  }

  const triggerRemoveItem = (itemIndex: number) => {
    const existingItems = formik.values.elements?.[index]?.params?.items || []
    formik.setFieldValue(`elements.${index}.params.items`, [
      ...existingItems.filter((item: any, index: number) => index !== itemIndex)
    ])
  }

  if(!editMode) {
    return (
      <div className="py-[80px] flex flex-col items-center bg-black text-white relative group/block">
        <h1
          className="font-bold text-8xl leading-[6rem] mb-[40px] text-center">
          {title || "Title here"}
        </h1>

        <div className="flex flex-row flex-nowrap justify-center">
          {items?.map((item, index) => (
            <div key={index} className="flex flex-col items-center gap-4 group/items">
              <div className="text-[5rem] leading-[5rem] font-bold relative w-full flex justify-center ">
                <div className="bg-black z-10 relative px-[30px]">{index + 1}</div>
                <div
                  className="absolute h-[10px] w-full top-1/2 -translate-y-1/2 bg-white z-0 group-first/items:w-[50%] group-first/items:right-0 group-last/items:w-[50%] group-last/items:left-0" />
              </div>
              <div className="p-[20px] text-center">
                <h2 className="font-medium text-2xl">{item.title}</h2>
                <p className="mb-0">{item.description}</p>
              </div>
            </div>
          )) || "Content here"}
        </div>

        <div
          className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover/block:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
        <TextInput
          name={`elements.${index}.params.title`}
          type="text"
          label="Section title"
          value={formik?.values.elements[index]?.params?.title}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
          className="mb-4"
        />
        <hr />

        <h3 className="text-xl font-bold">Items</h3>
        {formik.values.elements?.[index]?.params?.items?.map((item: any, itemIndex: number) => (
          <div key={itemIndex} className="grid grid-cols-1 gap-4 pl-[20px]">
            <TextInput
              name={`elements.${index}.params.items.${itemIndex}.title`}
              type="text"
              label="Title"
              value={formik?.values.elements[index]?.params?.items[itemIndex]?.title}
              showError={Boolean(formik?.touched.elements?.[index])}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.elements?.[index]?.toString()}
              required={true}
              className="mb-4"
            />
            <Textarea
              name={`elements.${index}.params.items.${itemIndex}.description`}
              label="Description"
              value={formik?.values.elements[index]?.params?.items[itemIndex]?.description}
              showError={Boolean(formik?.touched.elements?.[index])}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.elements?.[index]?.toString()}
              required={true}
            />
            <div className="flex justify-end">
              <CustomButton
                onClick={() => triggerRemoveItem(itemIndex)}
                title="Remove item"
                variant="danger"
              />
            </div>
          </div>
        ))}

        <div className="flex justify-center mb-[30px]">
          <CustomButton
            onClick={triggerAddItem}
            variant={"secondary"}
            title="Add item"
          />
        </div>

      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}

export default Steps