import React from "react";
import CustomButton from "../../atoms/button";
import { FormikProps } from "formik/dist/types";
import TextInput from "../../atoms/text-input";
import { Button } from "./components/button.component";
import Textarea from "../../atoms/textarea";
import { LandingPageItem } from "./types";

type MembersComponentProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  buttonHref?: string;
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const MembersComponent = ({ title, description, buttonText, buttonHref, formik, index }: MembersComponentProps) => {
  const [editMode, setEditMode] = React.useState(false);

  if(!editMode) {
    return (
      <div className="p-24 min-h-[300px] relative group">
        <div className="p-[40px] flex flex-col items-center justify-center text-center">
          <div className="max-w-full flex items-center justify-center flex-col">
            <h1
              className="text-[2rem] md:text-[5rem] font-medium leading-[2rem] md:leading-[5rem] mb-[20px] break-words max-w-full overflow-hidden">
              {title || 'Title Here'}
            </h1>
            <p className="text-[1rem] leading-[1.4rem] mb-[40px] font-medium md:max-w-[70%] text-center">
              {description || 'Description Here'}
            </p>
            {buttonText ? (
              <div className="">
                <Button variant="secondary" size="lg" className="block">
                  {buttonText || 'Button Text Here'}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
          <TextInput
            name={`elements.${index}.params.title`}
            type="text"
            label="Section title"
            value={formik?.values.elements[index]?.params?.title}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
            className="mb-4"
          />
          <Textarea
            name={`elements.${index}.params.description`}
            label="Section description"
            value={formik?.values.elements[index]?.params?.description}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />
          <TextInput
            name={`elements.${index}.params.buttonText`}
            type="text"
            label="Button text"
            value={formik?.values.elements[index]?.params?.buttonText}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
            className="mb-4"
          />
          <TextInput
            name={`elements.${index}.params.buttonHref`}
            type="text"
            label="Button url"
            value={formik?.values.elements[index]?.params?.buttonHref}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
          />

      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}
export default MembersComponent