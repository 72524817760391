import React from "react";
import { Navigate } from "react-router-dom";
import FogotPassword from "src/pages/auth/forgot-password";
import Login from "src/pages/auth/login";
import Users from "src/pages/users";
import VerifyOtp from "src/pages/auth/verify-otp";
import ResetPassword from "src/pages/auth/reset-password";
import ResetPasswordSuccess from "src/pages/auth/reset-password-success";
import ViewUser from "src/pages/view-user";
import AdminUsers from "src/pages/admin-users";
import Settings from "src/pages/settings";
import FeaturedMembers from "src/pages/featured-members";
import CreateUser from "src/pages/create-user";
import EditBio from "src/pages/edit-bio";
import PreviewBio from "src/pages/preview-bio";
import UserFeedback from "src/pages/user-feedback";
import ViewUserFeedback from "src/pages/view-user-feedback";
import Topics from "src/pages/topics";
import ViewTopic from "src/pages/view-topic";
import CreatePost from "src/pages/view-user/create-post";
import ViewPost from "src/pages/view-user/view-post";
import EditPost from "src/pages/view-user/edit-post";
import Library from "src/pages/library/listing";
import CreateArticle from "src/pages/library/create-article";
import EditArticle from "src/pages/library/edit-article";
import ViewArticle from "src/pages/library/view-article";
import HomepageEditor from "../pages/homepage-editor";
import LandingPagesList from "../pages/landing-pages/list";
import CreateLandingPage from "../pages/landing-pages/create";
import UpdateLandingPage from "../pages/landing-pages/update";

interface IRoutes {
  path: string;
  component: React.FC;
  isProtected: boolean;
}

/* This code is defining an array of protected and public routes for our application. The
 arrays contains an object with three properties: `path` (a string representing
the URL path for the route), `component` (a React component to render when the route is accessed),
and `isProtected` (a boolean indicating whether the route requires authentication to access). In
this case, the `Dashboard` component is protected and can only be accessed if the user is
authenticated. */

const commonRoutes: IRoutes[] = [
  {
    path: "/",
    component: () => <Navigate to="/login" />,
    isProtected: false,
  },
  {
    path: "/login",
    component: Login,
    isProtected: false,
  },
  {
    path: "/forgot-password",
    component: FogotPassword,
    isProtected: false,
  },
  {
    path: "/verify-otp",
    component: VerifyOtp,
    isProtected: false,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    isProtected: false,
  },
  {
    path: "/reset-password-success",
    component: ResetPasswordSuccess,
    isProtected: false,
  },
];

const adminRoutes: IRoutes[] = [
  {
    path: "/admin/homepage-editor",
    component: HomepageEditor,
    isProtected: true,
  },
  {
    path: "/admin/landing-pages",
    component: LandingPagesList,
    isProtected: true,
  },
  {
    path: "/admin/landing-pages/create",
    component: CreateLandingPage,
    isProtected: true,
  },
  {
    path: "/admin/landing-pages/update/:id",
    component: UpdateLandingPage,
    isProtected: true,
  },
  {
    path: "/admin/users",
    component: Users,
    isProtected: true,
  },
  {
    path: "/admin/users/create",
    component: CreateUser,
    isProtected: true,
  },
  {
    path: "/admin/users/:id/post/:postId",
    component: ViewPost,
    isProtected: true,
  },
  {
    path: "/admin/users/:id/post",
    component: CreatePost,
    isProtected: true,
  },
  {
    path: "/admin/users/:id/post/:postId/edit",
    component: EditPost,
    isProtected: true,
  },
  {
    path: "/admin/users/:id",
    component: ViewUser,
    isProtected: true,
  },

  {
    path: "/admin/users/:id/edit-bio",
    component: EditBio,
    isProtected: true,
  },
  {
    path: "/admin/users/:id/preview-bio",
    component: PreviewBio,
    isProtected: true,
  },
  {
    path: "/admin/admin-users",
    component: AdminUsers,
    isProtected: true,
  },
  {
    path: "/admin/featured-members",
    component: FeaturedMembers,
    isProtected: true,
  },
  {
    path: "/admin/settings",
    component: Settings,
    isProtected: true,
  },
  {
    path: "/admin/user-feedback",
    component: UserFeedback,
    isProtected: true,
  },
  {
    path: "/admin/user-feedback/:id",
    component: ViewUserFeedback,
    isProtected: true,
  },
  {
    path: "/admin/topics",
    component: Topics,
    isProtected: true,
  },
  {
    path: "/admin/topics/:id",
    component: ViewTopic,
    isProtected: true,
  },
  {
    path: "/admin/library",
    component: Library,
    isProtected: true,
  },
  {
    path: "/admin/library/article",
    component: CreateArticle,
    isProtected: true,
  },
  {
    path: "/admin/library/article",
    component: CreateArticle,
    isProtected: true,
  },
  {
    path: "/admin/library/article/:id",
    component: ViewArticle,
    isProtected: true,
  },
  {
    path: "/admin/library/article/:id/edit",
    component: EditArticle,
    isProtected: true,
  },
];

// const publicRoutes: IRoutes[] = [];

export { commonRoutes, adminRoutes };
