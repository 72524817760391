import MembersComponent from "./members.component";
import ArticlesComponent from "./articles.component";
import IntroComponent from "./intro.component";
import ImageAndTextComponent from "./image-and-text.component";
import ImageAndListComponent from "./image-and-list.component";
import CardsComponent from "./cards.component";
import StepsComponent from "./steps.component";
import BecomeMemberComponent from "./become-member.component";
import HeroComponent from "./hero.component";

export const componentsList = [
  {
    id: "hero",
    name: "Hero",
    component: HeroComponent
  },
  {
    id: 'members',
    name: 'Members',
    component: MembersComponent
  },
  {
    id: 'articles',
    name: 'Articles',
    component: ArticlesComponent
  },
  {
    id: 'intro',
    name: 'Intro',
    component: IntroComponent
  },
  {
    id: 'image-and-text',
    name: 'Image and Text',
    component: ImageAndTextComponent
  },
  {
    id: 'image-and-list',
    name: 'Image and List',
    component: ImageAndListComponent
  },
  {
    id: 'cards',
    name: 'Cards',
    component: CardsComponent
  },
  {
    id: 'steps',
    name: 'Steps',
    component: StepsComponent
  },
  {
    id: 'become-member',
    name: 'Become Member',
    component: BecomeMemberComponent
  }
]