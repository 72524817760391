import PanelLayout from "../../../components/layouts/panel-layout";
import SelectInput from "../../../components/atoms/select-input";
import React, { useEffect } from "react";
import CustomButton from "../../../components/atoms/button";
import { ReactSortable } from "react-sortablejs";
import { useFormik } from "formik";
import { Icon } from "../../../components/atoms/icons";
import {
  useCreateLandingPageMutation,
} from "../../../services/landing-pages";
import ButtonLoader from "../../../components/atoms/button-loader/button-loader.component";
import { LandingPageItem } from "../../../components/organisms/landing-page-components/types";
import { componentsList } from "../../../components/organisms/landing-page-components";
import TextInput from "../../../components/atoms/text-input";
import * as Yup from "yup";
import { showToast } from "../../../utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "../../../types";
import { useNavigate } from "react-router-dom";

const CreateLandingPage = () => {
  const redirect = useNavigate()
  const [selectedComponent, setSelectedComponent] = React.useState<string | null>(null);
  const [createLandingPage, createStatus] = useCreateLandingPageMutation();

  const formik = useFormik<{ elements: LandingPageItem[]; title: string; slug: string }>({
    initialValues: { elements: [], title: '', slug: '' },
    onSubmit: ({ title, slug, elements }) => {
      createLandingPage({
        title,
        slug,
        config: elements
      }).catch(error => {
        console.log("error", error);
      }).then((res: any) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, "Landing page created successfully");
        console.log("Rediredct", "/landing-pages/update/" + res.data?.id)
        if(res.data?.id) {
          redirect("/admin/landing-pages/update/" + res.data?.id);
        }
      });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      slug: Yup.string().required("Slug is required").min(3, "Slug must be at least 3 characters"),
      elements: Yup.array().of(Yup.object({
        id: Yup.string().required("Component id is required"),
        name: Yup.string().required("Component name is required"),
        component: Yup.object(),
        params: Yup.object()
      })).required("At least one component is required").min(1, "At least one component is required"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  })

  const handleAddComponent = () => {
    if(!selectedComponent) return;

    const component = componentsList.find((component) => component.id === selectedComponent);
    if(!component) return;

    formik.setFieldValue('elements', [
      {
        id: component.id,
        name: component.name,
        component: component.component,
        params: {}
      },
      ...formik.values.elements,
    ])

    setSelectedComponent(null)
  }

  const handleRemoveElement = (index: number) => {
    formik.setFieldValue('elements', formik.values.elements.filter((_, i) => i !== index) || [])
  }

  const preventPageReload = (e: any) => {
    return "Are you sure you want to leave this page? You will lose your unsaved changes."
  }

  useEffect(() => {
    if(formik.dirty) {
      window.addEventListener("beforeunload", preventPageReload)
      window.onbeforeunload = preventPageReload
    } else {
      window.removeEventListener("beforeunload", preventPageReload)
      // @ts-ignore
      window.onbeforeunload = undefined
    }

    return () => {
      window.removeEventListener("beforeunload", preventPageReload)
      // @ts-ignore
      window.onbeforeunload = undefined
    }
  }, [formik.dirty])

  useEffect(() => {
    if(createStatus.isError) {
      showToast(toast,TOASTR_TYPES.ERROR, (createStatus?.error as any)?.data?.message)
    }

  }, [createStatus]);

  return (
    <PanelLayout wrapperClass={`px-24 py-20 text-white`}>
      <div className="grid grid-cols-1 gap-4 mb-40">
        <TextInput
          name="title"
          type="text"
          label="Page title"
          value={formik?.values.title}
          showError={Boolean(formik?.touched.title)}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.title?.toString()}
          required={true}
        />
        <TextInput
          name="slug"
          type="text"
          label="Page slug"
          value={formik?.values.slug}
          showError={Boolean(formik?.touched.slug)}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.slug?.toString()}
          required={true}
        />
      </div>
      <div className="flex justify-between pb-10">
        <CustomButton
          disabled={!formik.dirty || createStatus.isLoading}
          variant="primary"
          onClick={() => formik.handleSubmit()}
        >
          <div className="flex items-center">
            Save changes
            {formik.dirty ? (<span className="ml-2"><Icon.IcDarkUpload /></span>) : null}
          </div>
        </CustomButton>

        <div className="flex justify-end items-center gap-2">
          <SelectInput
            name="component"
            value={selectedComponent}
            placeholder="Select component"
            options={componentsList.map((component) => ({ value: component.id, label: component.name }))}
            onChange={(value: string) => {
              setSelectedComponent(value);
            }}
          />
          <CustomButton
            disabled={!selectedComponent}
            title="Add component"
            variant="secondary"
            onClick={handleAddComponent}
          />
        </div>
      </div>


      <div className="mt-20 relative">
        {(createStatus.isLoading) ? (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 flex items-center justify-center min-h-[200px]">
            <div className="text-white">
              <ButtonLoader loading />
            </div>
          </div>
        ) : null}
        <ReactSortable
          list={formik.values.elements || []}
          setList={(values) => formik.setFieldValue('elements', values)}
          handle={".handle"}
        >
          {(formik.values.elements || []).map(({ component, params }, index) => {
            const lpComponent = React.createElement(component, { ...params, formik, index });
            return (
              <div key={index} className="bg-gray-800 p-4 mb-4 pl-[60px] relative group min-h-[200px]">
                <div
                  className="absolute top-0 left-0 bottom-0 w-[0px] flex flex-col gap-3 items-center justify-center text-white group-hover:w-[60px] overflow-hidden transition-all">
                  <div className="handle cursor-grab rounded p-5 hover:bg-gray-200">
                    <Icon.IcDrag />
                  </div>

                  <div className="text-red-500 rounded p-5 hover:bg-gray-200 cursor-pointer" onClick={() => handleRemoveElement(index)}>
                    <Icon.IcDelete />
                  </div>
                </div>
                {lpComponent}
              </div>
            )
          })}
        </ReactSortable>
        {formik.errors?.elements ? (
          <div className="text-red-500 text-center py-40">{formik.errors.elements.toString()}</div>
        ) : null}
      </div>
    </PanelLayout>
  )
}
export default CreateLandingPage;