import React from "react";
import { Icon } from "../icons";
import { IImageUploadComponent } from "./image-upload.types";
import ErrorLabel from "../error-label";
import ButtonLoader from "../button-loader/button-loader.component";
import Label from "../label";

const ImageUploadComponent = ({
  id,
  label,
  name,
  className = "",
  handleChange,
  handleDelete,
  image,
  showError,
  errorMessage,
  loading,
  uploadError,
  required,
}: IImageUploadComponent) => {
  return (
    <div className="relative flex flex-col">
      {label && (
        <Label name={label} id={id || name} required={Boolean(required)} />
      )}
      <input
        id="input-file"
        type="file"
        className={`absolute inset-0 ${image ? "z-10" : "z-20"} z-10 h-full w-full cursor-pointer opacity-0`}
        onChange={handleChange}
        accept="image/jpeg, image/png, image/jpg, image/svg+xml"
      />

      <div
        className={`group relative flex h-[143px] w-[143px] cursor-pointer items-center justify-center rounded-sm  ${className}`}
      >
        {loading ? (
          <ButtonLoader loading={loading} />
        ) : (
          <>
            {!image && <Icon.IcUploadImage />}

            {image && (
              <img src={image} className="h-full w-full object-contain"></img>
            )}
            <div className="absolute z-10 h-full w-full rounded-sm bg-upload-image-overlay transition duration-75 group-hover:block">
              <div className="flex h-full items-center justify-center gap-6">
                {image && (
                  <button
                    className="z-20 flex items-center justify-center rounded-full bg-white-heading p-8"
                    onClick={handleDelete}
                  >
                    <Icon.IcDarkBin />
                  </button>
                )}
                {image && (
                  <button
                    className="z-20 flex items-center justify-center rounded-full bg-white-heading p-8"
                    onClick={() => {
                      document.getElementById("input-file")?.click();
                    }}
                  >
                    <Icon.IcDarkUpload />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {(showError || uploadError) && (
        <ErrorLabel message={uploadError || errorMessage} />
      )}
    </div>
  );
};

export default ImageUploadComponent;
