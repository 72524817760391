import React from "react";
import { cn } from "../../../../utils";

export type MemberCardProps = {
  name: string,
  role: string,
  image: string,
}
export const MemberCard = ({
  name,
  role,
  image,
}: MemberCardProps) => {
  return (
    <div
      className={
        cn(
          "bg-primary-color p-[10px] rounded-br-[30px] shadow-member-shadow cursor-pointer flex flex-col",
          "transition-all duration-300",
          "hover:scale-105", // when hovering move it up a bit
        )
      }
    >
      <div className="mb-5 grow">
        <img
          src={image}
          alt={name}
          width={370}
          height={370}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="text-gray-100 grow-0 shrink-0">
        <div className="font-bold uppercase text-xl text-gray-100 flex items-center justify-between" >
          {name}
        </div>
        <div className="text-sm">
          {role}
        </div>
      </div>
    </div>
  )
}