import React from "react";
import { FormikProps } from "formik/dist/types";
import TextInput from "../../atoms/text-input";
import Textarea from "../../atoms/textarea";
import CustomButton from "../../atoms/button";
import ImageUpload from "../../atoms/image-upload";
import { LandingPageItem } from "./types";

export type ImageAndTextProps = {
  title?: string
  description?: string
  imageUrl?: string
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const ImageAndTextComponent = ({ title, description, imageUrl, formik, index }: ImageAndTextProps) => {
  const [editMode, setEditMode] = React.useState(false);

  if(!editMode) {
    return (
      <div className="py-[80px] flex flex-row items-center bg-black text-white relative group">
        <div className="flex flex-col md:flex-row flex-nowrap justify-center gap-10 px-[20px] md:px-0 w-full">
          <div className="flex justify-center items-center md:w-[40%]">
            <img src={imageUrl || "https://placehold.co/400?text=User+Image"} alt="Putiton Badge"
                 className="w-[140px] block" />
          </div>
          <div className="w-full md:max-w-[60%] py-10">
            <h1
              className="font-bold text-4xl leading-[3.2rem] underline underline-offset-[10px] mb-[20px]">
              {title || 'Title Here'}
            </h1>
            <p className="text-[1.2rem] leading-[1.6rem]"
               dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, "<br />") || "Content here" }}
            >
            </p>


          </div>
        </div>
        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
        <TextInput
          name={`elements.${index}.params.title`}
          type="text"
          label="Section title"
          value={formik?.values.elements[index]?.params?.title}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
          className="mb-4"
        />
        <Textarea
          name={`elements.${index}.params.description`}
          label="Section description"
          value={formik?.values.elements[index]?.params?.description}
          showError={Boolean(formik?.touched.elements?.[index])}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          errorMessage={formik?.errors.elements?.[index]?.toString()}
          required={true}
        />
        <ImageUpload
          label="Image"
          name={`elements.${index}.params.imageUrl`}
          value={imageUrl}
          className="bg-gray-400"
          onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.imageUrl`, img)}
          image={imageUrl}
          showError={Boolean(formik?.touched.elements?.[index])}
          errorMessage={formik?.errors.elements?.[index].toString()}
          setDisabled={false}
          isOpenModal={true}
          useCropper={false}
        />


      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}

export default ImageAndTextComponent