export const ENDPOINTS = {
  AUTH: {
    LOGIN: "/api/sign-in",
    FORGOT_PASSWORD: "/api/forgot-password",
    VERIFY_OTP: "/api/forgot-password-verify-code",
    RESET_PASSWORD: "/api/reset-password",
    VERIFY_RECAPTCHA: "/api/recaptcha",
    CHANGE_PASSWORD: "/api/admin/change-password",
    CHANGE_EMAIL: "/api/admin/update-email",
    GET_CURRENT_USER: "/api/user",
    UPLOAD_FILE: "/api/image-upload",
    CHECK_EMAIL: "/auth/check-email",
    RESEND_OTP: "/auth/resend-otp",
    CHECK_EMAIL_EXISTS: "/api/email-already",
    CHECK_PHONE: "/auth/check-phone",
    CHECK_ALREADY_EXISTS: "/auth/check-already-exists",
    GET_PROFILE: "/auth/profile",
  },
  FEATURED_MEMBERS: {
    GET_FEATURED_MEMBERS: "/api/admin/list-feature-member",
    DELETE_FEATURED_MEMBER: "/api/admin/delete-feature-member",
    GET_MEMBERS_LISTING: "/api/admin/list-member",
    ADD_FEATURED_MEMBER: "/api/admin/add-feature-member",
  },
  ADMIN_MEMBERS: {
    GET_ADMIN_MEMBERS: "/api/admin/list-admin-user",
    ADD_ADMIN_USER: "/api/admin/create-admin-user",
    EDIT_ADMIN_USER: "/api/admin/update-admin-user",
    DELETE_ADMIN_USER: "/api/admin/delete-admin-user",
  },
  USERS: {
    GET_USER_LISTING: "/api/admin/user",
    DELETE_USER: "/api/admin/delete-user",
    ADD_USER: "/api/admin/add-user",
    EDIT_USER: "/api/admin/update-user-profile",
    GET_USER: "/api/admin/view-user",
    EDIT_USER_EMAIL: "/api/admin/update-user",
    EDIT_USER_KEYWORDS: "/api/admin/update-user-keywords",
    DRAFT_BIO: "/api/admin/user-draft-biography",
    UPDATE_UESR_BIO: "/api/admin/update-user-biography",
    DRAG_DROP_BIO: "/api/admin/user-drag-drop-biography",
    PUBLISH_BIO: "/api/admin/user-publish-bio",
    DELETE_BIO_SECTION: "/api/admin/delete-publish-biography",
    DASHBOARD_STATS: "/api/admin/dashboard-stats",
    PUBLISH_UNPUBLISH_BIO: "/api/admin/user-update-bio-status",
    GET_USER_ANALYTICS: "/api/admin/user-analytics",
    TOGGLE_CONTENT_LEADER: "/api/admin/give-content-leader-badge",
  },
  USER_FEEDBACK: {
    GET_LISTING: "/api/admin/list-feedback",
    DELETE: "/api/admin/delete-user-feedback",
    GET_USER_FEEDBACK: "/api/admin/feedback",
    DASHBOARD: "/api/admin/feedback-cards",
  },
  TOPICS: {
    GET_LISTING: "/api/admin/topics",
    GET_ALL_TOPICS: "/api/admin/topics-list",
    GET_TOPIC: "/api/admin/topics",
    ADD_TOPIC: "/api/admin/topics",
    EDIT_TOPIC: "/api/admin/topics",
    DELETE: "/api/admin/topics",
  },
  POSTS: {
    GET_AUTHOR_POSTS: "/api/admin/authors",
    CREATE_POST: "/api/admin/posts",
    UPDATE_POST: "/api/admin/posts",
    GET_POST: "/api/admin/posts",
    PUBLISH_POST: "/api/admin/posts",
    DELETE_POST: "/api/admin/posts",
    DELETE_POST_SECTION: "api/admin/contents",
  },
  ARTICLES: {
    CREATE_ARTICLE: "/api/admin/article",
    UPDATE_ARTICLE: "/api/admin/article",
    GET_ARTICLE: "/api/admin/article",
    GET_ALL_ARTICLES: "/api/admin/articles",
    PUBLISH_ARTICLE: "/api/admin/articles",
    DELETE_ARTICLE: "/api/admin/articles",
    DELETE_ARTICLE_SECTION: "api/admin/article-contents",
    GET_ALL_MEMBERS: "/api/encyclopedia/all-experts",
    GET_ALL_RELATED_POSTS: "/api/encyclopedia/all-related-posts",
  },
  ADMIN_LANDING_PAGE: {
    GET_HOMEPAGE_CONFIG: "/api/admin/homepage-config",
    UPDATE_HOMEPAGE_CONFIG: "/api/admin/homepage-config",
    LIST: "/api/admin/landing-pages",
    CREATE: "/api/admin/landing-pages",
    GET: "/api/admin/landing-pages",
    UPDATE: "/api/admin/landing-pages",
    DELETE: "/api/admin/landing-pages",
  },
};
