import { useDeleteLandingPageMutation, useGetLandingPagesMutation } from "../../../services/landing-pages";
import React, { useState } from "react";
import usePagination from "../../../hooks/usePagination";
import CustomButton from "../../../components/atoms/button";
import DataTableComponent from "../../users/elements/data-table/data-table.component";
import { Link } from "react-router-dom";
import { Icon } from "../../../components/atoms/icons";
import PanelLayout from "../../../components/layouts/panel-layout";
import ConfirmDelete from "../../../components/organisms/confirm-delete";
import useModal from "../../../hooks/useModal";

export const LandingPagesList = () => {
  const [getLandingPages, getStatus] = useGetLandingPagesMutation();
  const [deleteLandingPage, deleteStatus] = useDeleteLandingPageMutation();
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({ key: "landing-pages-datatable", mutation: getLandingPages });

  const triggerDeleteUser = () => {
    deleteLandingPage({ id: isDeleteOpen }).then(() => {
      fetchData({ currentPage: 1, query: "" });
      setDeleteOpen(false);
    })
  }

  return (
    <PanelLayout wrapperClass={`px-24 py-20 text-white`}>
      <div className="pb-10">
        <DataTableComponent
          title={"Landing pages"}
          description={`${pagination?.total == 0 ? "No landing pages" : pagination?.total > 1 ? `Total ${pagination?.total} landing pages` : `Total ${pagination?.total} landing pages`} `}
          className="date-picker"
          data={data}
          columns={[
            {
              name: "Title",
              selector: (row: { title: string }) => row.title,
              sortable: true,
              width: "132px",
            },
            {
              name: "Slug",
              selector: (row: { slug: string }) => row.slug,
              sortable: true,
              width: "132px",
            },
            {
              name: "",
              cell: (row: any) => {
                return (
                  <>
                    <div className="flex items-center justify-end">
                      <Link to={`/admin/landing-pages/update/${row?.id}`}>
                        <button className="cursor-pointer p-12">
                          <Icon.IcView />
                        </button>
                      </Link>
                      <button
                        className="cursor-pointer p-12"
                        onClick={() => setDeleteOpen(row?.id)}
                      >
                        <Icon.IcDelete />
                      </button>
                    </div>
                  </>
                );
              },
              ignoreRowClick: true,
              allowOverflow: true,
              button: true,
            },
          ]}
          showHeader={true}
          showFooter={true}
          pagination={pagination}
          handleSearch={handleSearch}
          handlePageChange={handlePageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
          // onRowClicked={onRowClicked}
          clearSearch={clearSearch}
          button={
            <Link to={`/admin/landing-pages/create`}>
              <CustomButton
                title="Add landing page"
                variant="primary"
                onClick={() => {
                }}
              />
            </Link>
          }
        />

        <ConfirmDelete
          title="Are you sure you want to remove this landing page?"
          message="The data you delete cannot be accessed again"
          onConfirm={triggerDeleteUser}
          toggle={() => setDeleteOpen(false)}
          isOpen={isDeleteOpen}
          loading={deleteStatus.isLoading}
        />
      </div>
    </PanelLayout>
  )
}
export default LandingPagesList;