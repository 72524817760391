import PanelLayout from "../../components/layouts/panel-layout";
import SelectInput from "../../components/atoms/select-input";
import React, { useEffect } from "react";
import CustomButton from "../../components/atoms/button";
import { ReactSortable } from "react-sortablejs";
import { useFormik } from "formik";
import { Icon } from "../../components/atoms/icons";
import {
  useGetHomepageConfigMutation,
  useUpdateHomepageConfigMutation,
} from "../../services/landing-pages";
import ButtonLoader from "../../components/atoms/button-loader/button-loader.component";
import { componentsList } from "../../components/organisms/landing-page-components";
import { LandingPageItem } from "../../components/organisms/landing-page-components/types";

const HomepageEditor = () => {
  const [selectedComponent, setSelectedComponent] = React.useState<string | null>(null);

  const [getHomepageConfigMutation, getStatus] = useGetHomepageConfigMutation();
  const [updateHomepageConfigMutation, updateStatus] = useUpdateHomepageConfigMutation();
  const [initialData, setInitialData] = React.useState({ elements: [] });


  const getLandingPageConfig = async () => {
    const response = await getHomepageConfigMutation({}).unwrap() as any

    if(response) {
      const elements = response.map((element: any) => {
        const component = componentsList.find((component) => component.id === element.id);
        if(!component) return
        return {
          ...element,
          component: component?.component,
          params: Array.isArray(element.params) ? {} : (element.params || {})
        }
      }).filter(Boolean)

      setInitialData({ elements })
    }
  }


  const formik = useFormik<{ elements: LandingPageItem[] }>({
    initialValues: initialData,
    onSubmit: ({ elements }) => {
      updateHomepageConfigMutation({
        config: elements
      }).then(() => {
        getLandingPageConfig()
      })
    },
    enableReinitialize: true
  })

  const handleAddComponent = () => {
    if(!selectedComponent) return;

    const component = componentsList.find((component) => component.id === selectedComponent);
    if(!component) return;

    formik.setValues({
      elements: [
        {
          id: component.id,
          name: component.name,
          component: component.component,
          params: {}
        },
        ...formik.values.elements,
      ]
    })

    setSelectedComponent(null)
  }

  const handleRemoveElement = (index: number) => {
    formik.setValues({
      elements: formik.values.elements.filter((_, i) => i !== index)
    })
  }

  const preventPageReload = (e: any) => {
    return "Are you sure you want to leave this page? You will lose your unsaved changes."
  }

  useEffect(() => {
    getLandingPageConfig()
  }, [])


  useEffect(() => {
    if(formik.dirty) {
      window.addEventListener("beforeunload", preventPageReload)
      window.onbeforeunload = preventPageReload
    } else {
      window.removeEventListener("beforeunload", preventPageReload)
      // @ts-ignore
      window.onbeforeunload = undefined
    }

    return () => {
      window.removeEventListener("beforeunload", preventPageReload)
      // @ts-ignore
      window.onbeforeunload = undefined
    }
  }, [formik.dirty])

  // console.log("form values", formik.values)

  return (
    <PanelLayout wrapperClass={`px-24 py-20 text-white`}>
      {/*<h1 className={`text-4xl font-bold`}>Landing Page config</h1>*/}
      <div className="flex justify-between pb-10">
        <CustomButton
          disabled={!formik.dirty || getStatus.isLoading || updateStatus.isLoading}
          variant="primary"
          onClick={() => formik.handleSubmit()}
        >
          <div className="flex items-center">
            Save changes
            {formik.dirty ? (<span className="ml-2"><Icon.IcDarkUpload /></span>) : null}
          </div>
        </CustomButton>

        <div className="flex justify-end items-center gap-2">
          <SelectInput
            name="component"
            value={selectedComponent}
            placeholder="Select component"
            options={componentsList.map((component) => ({ value: component.id, label: component.name }))}
            onChange={(value: string) => {
              setSelectedComponent(value);
            }}
          />
          <CustomButton
            disabled={!selectedComponent}
            title="Add component"
            variant="secondary"
            onClick={handleAddComponent}
          />
        </div>
      </div>


      <div className="mt-20 relative">
        {(getStatus.isLoading || updateStatus.isLoading) ? (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 flex items-center justify-center min-h-[200px]">
            <div className="text-white">
              <ButtonLoader loading />
            </div>
          </div>
        ) : null}
        <ReactSortable
          list={formik.values.elements || []}
          setList={(values) => formik.setValues({ elements: values })}
          handle={".handle"}
        >
          {(formik.values.elements || []).map(({ component, params }, index) => {
            const lpComponent = React.createElement(component, { ...params, formik, index });
            return (
              <div key={index} className="bg-gray-800 p-4 mb-4 pl-[60px] relative group min-h-[200px]">
                <div
                  className="absolute top-0 left-0 bottom-0 w-[0px] flex flex-col gap-3 items-center justify-center text-white group-hover:w-[60px] overflow-hidden transition-all">
                  <div className="handle cursor-grab rounded p-5 hover:bg-gray-200">
                    <Icon.IcDrag />
                  </div>

                  <div className="text-red-500 rounded p-5 hover:bg-gray-200 cursor-pointer" onClick={() => handleRemoveElement(index)}>
                    <Icon.IcDelete />
                  </div>
                </div>
                {lpComponent}
              </div>
            )
          })}
        </ReactSortable>
      </div>
    </PanelLayout>
  )
}
export default HomepageEditor;