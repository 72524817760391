import React from "react";
import ImageUpload from "../../atoms/image-upload";
import CustomButton from "../../atoms/button";
import { FormikProps } from "formik/dist/types";
import TextInput from "../../atoms/text-input";
import { LandingPageItem } from "./types";

export type BecomeMemberProps = {
  buttonText?: string
  buttonHref?: string
  imageUrl?: string
  formik: FormikProps<{ elements: LandingPageItem[] }>
  index: number
}
export const BecomeMember = ({ buttonText, buttonHref, imageUrl, formik, index }: BecomeMemberProps) => {
  const [editMode, setEditMode] = React.useState(false);

  if(!editMode) {
    return (
      <div className="py-[80px] relative group">
        <div className=" flex items-center justify-center flex-col">
          <button
            className="border-[1px] border-primary-color p-[5px] rounded-full mb-[50px]"
          >
            <div
              className="bg-primary-color p-[20px] rounded-full uppercase w-[200px] h-[200px] text-center text-white flex items-center justify-center shrink-0 overflow-hidden font-bold text-2xl hover:scale-105 transition-all">
              {buttonText || "Button text here"}
            </div>
          </button>
          {imageUrl ? (
            <img src={imageUrl} alt="Become a member" className="w-full" />
          ) : null}
        </div>

        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
          <CustomButton
            onClick={() => setEditMode(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-24">
      <div className="grid grid-cols-1 gap-4">
          <TextInput
            name={`elements.${index}.params.buttonText`}
            type="text"
            label="Button text"
            value={formik?.values.elements[index]?.params?.buttonText}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
            className="mb-4"
          />
          <TextInput
            name={`elements.${index}.params.buttonHref`}
            type="text"
            label="Button url"
            placeholder="/members"
            value={formik?.values.elements[index]?.params?.buttonHref}
            showError={Boolean(formik?.touched.elements?.[index])}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.elements?.[index]?.toString()}
            required={true}
            className="mb-4"
          />
          <ImageUpload
            label="Image"
            name={`elements.${index}.params.imageUrl`}
            value={imageUrl}
            className="bg-gray-400"
            onChange={(img: string) => formik?.setFieldValue(`elements.${index}.params.imageUrl`, img)}
            image={imageUrl}
            showError={Boolean(formik?.touched.elements?.[index])}
            errorMessage={formik?.errors.elements?.[index].toString()}
            setDisabled={false}
            isOpenModal={true}
            useCropper={false}
          />
      </div>
      <div className="mt-15 flex justify-center">
        <CustomButton
          onClick={() => setEditMode(false)}
          title="Back to preview"
        />
      </div>
    </div>
  )
}
export default BecomeMember